import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const userStyles = makeStyles(() => ({
  root: {
    userSelect: 'none',
    minHeight: '100vh',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorNum: {
    'font-size': '110px',
    'line-height': '90px',
    'letter-spacing': '10px',
    color: 'transparent',
    'background-image': 'linear-gradient(92.14deg, #db4940 0%, #c81f51 100%)',
    '-webkit-background-clip': 'text',
    'background-clip': 'text',
    'margin-bottom': '30px',
  },
  text: {
    'font-size': '28px',
    'letter-spacing': '5px',
  },
}));

const NotFound: React.FC = () => {
  const classes = userStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.errorNum}>404</Typography>
      <Typography className={classes.text}>OOPS! NOTHING WAS FOUND</Typography>
    </div>
  );
};

export default NotFound;
