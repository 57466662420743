import React from 'react';
import { Box, Typography } from '@material-ui/core';
import TopData from './components/TopData';
import YahooReportDataTable from './components/YahooReportDataTable';
import RevenueGraph from './components/RevenueGraph';
import FilterOptions from './components/FilterOptions';
import SearchesTable from './components/SearchesTable';
import AdoptionTable from './components/AdoptionTable';
import AffiliateLink from './components/AffiliateLink';

const Dashboard: React.FC = () => {
  return (
    <>
      <Box>
        <Typography variant='h5'>Dashboard</Typography>
        <AffiliateLink />
      </Box>
      <FilterOptions />
      <YahooReportDataTable />
      <RevenueGraph />

      <Box
        mt={2}
        display='flex'
        maxWidth={1280}
        flexDirection='row'
        justifyContent='space-between'
      >
        <SearchesTable />
        <AdoptionTable />
      </Box>
      <TopData />
    </>
  );
};

export default Dashboard;
