import React, { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { Link } from '@material-ui/core';
import { SocialMedia } from 'src/models';
import { IMetaData } from 'src/types/MetaData';
import DataTable from 'src/components/table/DataTable';

interface Props {
  socialMedias: SocialMedia[];
  meta: IMetaData;
  isLoading?: boolean;
  getData: (meta?: Partial<IMetaData>) => void;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
}

const SocialMediasTable: React.FC<Props> = ({
  socialMedias,
  meta,
  isLoading,
  getData,
  onDelete,
  onEdit,
}) => {
  const renderRow = useCallback((prop: keyof SocialMedia, row: SocialMedia) => {
    switch (prop) {
      case 'name':
        return (
          <Link
            component='a'
            target='_blank'
            href={row.url.includes('http') ? row.url : `http://${row.url}`}
          >
            {row[prop]}
          </Link>
        );
      case 'updatedAt':
      case 'createdAt':
        return DateTime.fromISO(row[prop]?.toString() ?? '').toLocaleString(
          DateTime.DATETIME_SHORT
        );
      default:
        return row[prop];
    }
  }, []);

  const cells: { label: string; key: keyof SocialMedia }[] = useMemo(
    () => [
      { label: 'Name', key: 'name' },
      { label: 'Rank', key: 'rank' },
      { label: 'Partner', key: 'partnerName' },
      { label: 'Updated at', key: 'updatedAt' },
      { label: 'Created at', key: 'createdAt' },
    ],
    []
  );

  return (
    <DataTable<SocialMedia>
      useActions
      data={socialMedias}
      meta={meta}
      headerCells={cells}
      isLoading={!!isLoading}
      renderCell={renderRow}
      getData={getData}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
};

export default SocialMediasTable;
