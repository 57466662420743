import * as _ from 'lodash';
import * as types from './actionTypes';
import Immutable from 'seamless-immutable';
import { IReportsStoreState } from 'src/types/ReportsStoreState';
import { getInitialMeta } from 'src/types/MetaData';

const reportsStoreInitialMeta = getInitialMeta({
  sort: 'search_type',
  queryParams: {},
  limit: 10,
});

const emptyState: IReportsStoreState = {
  isWaiting: true,
  reports: [],
  meta: reportsStoreInitialMeta,
  filterOptions: null,
};

const initialState = Immutable(_.cloneDeep(emptyState));

export default function reduce(state = initialState, action: any = {}): any {
  switch (action.type) {
    case types.GET_REPORTS_SUCCESS:
      return {
        ...state,
        isWaiting: false,
        ...action.payload,
      };

    case types.SET_WAITING:
      return { ...state, isWaiting: action.payload };

    case types.APPEND_META:
      return { ...state, meta: { ...state.meta, ...action.payload } };

    case types.SET_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: action.payload,
      };

    default:
      return state;
  }
}
