import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import * as luxon from 'luxon';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import EditIcon from '@material-ui/icons/Edit';
import { FormikHelpers, useFormik } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Accordion from 'src/components/containers/Accordion/Accordion';
import { countries, marketConstants, csvHeaders } from 'src/constants';
import { getFilterOptions, getReports } from 'src/store/reports/actions';
import { Autocomplete } from '@material-ui/lab';

import {
  Box,
  Grid,
  Button,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  makeStyles,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { AllState } from 'src/types/AllState';

const useStyles = makeStyles((theme) => ({
  formRoot: {
    width: '100%',
  },
  inputwrap: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    justifyContent: 'flex-end',

    '& > div:last-child': {
      width: '70% !important',
    },
  },
  csvLink: {
    textDecoration: 'none',
    color: 'white',
  },
}));

interface FormProps {
  startDate: luxon.DateTime;
  endDate: luxon.DateTime;
  predefinedDates: string;
  partner: number | string;
  product: string;
  device: string;
  market: string;
  source: string;
  country: string;
}

const initialValues: FormProps = {
  startDate: luxon.DateTime.now().startOf('month'),
  endDate: luxon.DateTime.now(),
  predefinedDates: 'Current month',
  partner: '',
  product: 'all',
  device: 'all',
  market: 'all',
  source: 'all',
  country: 'all',
};

const validationSchema = object().shape({
  country: string()
    .required('Country is required.')
    .typeError('Country is required.'),
});

function usePrevious(value: any): any {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

const FilterOptions: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { reports, filterOptions } = useSelector(({ reports }: AllState) => ({
    reports: reports.reports,
    filterOptions: reports.filterOptions,
  }));

  const [isDateError, setDateError] = useState<boolean>(false);

  const handleGetReports = useCallback(
    (values: FormProps) => {
      const queryParams: Record<string, any> = { ...values };

      queryParams.startDate = queryParams.startDate.toISODate();
      queryParams.endDate = queryParams.endDate.toISODate();

      dispatch(getReports({ queryParams }));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    (values: FormProps, formik: FormikHelpers<FormProps>) => {
      if (!isDateError) {
        handleGetReports(values);
      }
    },
    [isDateError, handleGetReports]
  );

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });

  const handleResetFilters = useCallback(() => {
    formik.resetForm();

    handleGetReports(formik.values);
  }, [formik, handleGetReports]);

  const onDateAccept = useCallback(() => setDateError(false), [setDateError]);

  const onDateError = useCallback(
    (e: any) => {
      if (e) {
        setDateError(true);
      }
    },
    [setDateError]
  );

  useEffect(() => {
    dispatch(getFilterOptions());
    handleGetReports(formik.values);
    // eslint-disable-next-line
  }, []);

  const prevFormik: any = usePrevious(formik);

  useEffect(() => {
    if (
      prevFormik != null &&
      prevFormik.values.predefinedDates === formik.values.predefinedDates
    ) {
      return;
    }

    let startDate: moment.Moment;
    let endDate: moment.Moment;

    switch (formik.values.predefinedDates.toLowerCase()) {
      case 'yesterday':
        endDate = moment().subtract(1, 'day').endOf('day');
        startDate = endDate.clone().startOf('day');
        break;

      case 'current month':
        endDate = moment().subtract(1, 'day').endOf('day');
        startDate = endDate.clone().startOf('day');
        break;

      case 'last 30 days':
        endDate = moment().endOf('day');
        startDate = endDate.clone().subtract(30, 'day').startOf('day');
        break;

      case 'last week':
        endDate = moment().subtract(1, 'week').endOf('week');
        startDate = endDate.clone().startOf('week');
        break;

      case 'current year':
        endDate = moment().endOf('year');
        startDate = moment().endOf('day');
        break;

      default:
        return;
    }

    formik.handleChange({
      target: {
        value: luxon.DateTime.fromISO(startDate.toISOString()),
        name: 'startDate',
      },
    });

    formik.handleChange({
      target: {
        value: luxon.DateTime.fromISO(endDate.toISOString()),
        name: 'endDate',
      },
    });
    // eslint-disable-next-line
  }, [formik]);

  return (
    <Box maxWidth={1280} pt={3}>
      <Accordion headerLabel='Filter Options' headerIcon={EditIcon}>
        <form onSubmit={formik.handleSubmit} className={classes.formRoot}>
          <Grid container spacing={3} justify='space-around'>
            <Grid item xs={12} md={6} lg={5}>
              <div className={classes.inputwrap}>
                <InputLabel className='Mui-sideLabel'>Start Date</InputLabel>
                <KeyboardDatePicker
                  disableFuture
                  variant='inline'
                  inputVariant='outlined'
                  format='MM/dd/yyyy'
                  margin='dense'
                  inputValue={
                    !isDateError
                      ? formik.values.startDate.toFormat('MM/dd/yyyy')
                      : ''
                  }
                  value={formik.values.startDate}
                  InputAdornmentProps={{ position: 'start' }}
                  onAccept={onDateAccept}
                  onError={onDateError}
                  onChange={(date) => {
                    formik.handleChange({
                      target: {
                        value: '',
                        name: 'predefinedDates',
                      },
                    });

                    formik.handleChange({
                      target: {
                        value: date,
                        name: 'startDate',
                      },
                    });
                  }}
                />
              </div>
              <div className={classes.inputwrap}>
                <InputLabel className='Mui-sideLabel'>
                  Predefined Dates
                </InputLabel>
                <FormControl
                  variant='outlined'
                  margin='dense'
                  error={
                    formik.touched.predefinedDates &&
                    Boolean(formik.errors.predefinedDates)
                  }
                >
                  <Select
                    value={formik.values.predefinedDates}
                    onChange={formik.handleChange}
                    name='predefinedDates'
                    displayEmpty
                  >
                    <MenuItem value=''>None</MenuItem>
                    <MenuItem value='Yesterday'>Yesterday</MenuItem>
                    <MenuItem value='Current month'>Current month</MenuItem>
                    <MenuItem value='Last 30 days'>Last 30 days</MenuItem>
                    <MenuItem value='Last week'>Last week</MenuItem>
                    <MenuItem value='Last month'>Last month</MenuItem>
                    <MenuItem value='Current year'>Current year</MenuItem>
                  </Select>
                  <FormHelperText>
                    {formik.touched.predefinedDates &&
                      formik.errors.predefinedDates}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className={classes.inputwrap}>
                <InputLabel className='Mui-sideLabel'>Partner</InputLabel>
                <FormControl
                  variant='outlined'
                  margin='dense'
                  error={
                    formik.touched.partner && Boolean(formik.errors.partner)
                  }
                >
                  <Select
                    value={formik.values.partner}
                    onChange={formik.handleChange}
                    name='partner'
                    displayEmpty
                  >
                    <MenuItem value=''>Select One</MenuItem>
                    {filterOptions?.partners?.map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.partner && formik.errors.partner}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className={classes.inputwrap}>
                <InputLabel className='Mui-sideLabel'>Product</InputLabel>
                <FormControl
                  variant='outlined'
                  margin='dense'
                  error={
                    formik.touched.product && Boolean(formik.errors.product)
                  }
                >
                  <Select
                    value={formik.values.product}
                    onChange={formik.handleChange}
                    name='product'
                  >
                    <MenuItem value='all'>all</MenuItem>
                    {filterOptions?.products?.map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.product && formik.errors.product}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className={classes.inputwrap}>
                <InputLabel className='Mui-sideLabel'>Device</InputLabel>
                <FormControl
                  variant='outlined'
                  margin='dense'
                  error={formik.touched.device && Boolean(formik.errors.device)}
                >
                  <Select
                    value={formik.values.device}
                    onChange={formik.handleChange}
                    name='device'
                  >
                    <MenuItem value='all'>all</MenuItem>
                    <MenuItem value='Desktop'>Desktop</MenuItem>
                    <MenuItem value='Feature Phones'>Feature Phones</MenuItem>
                    <MenuItem value='Smart Phones'>Smart Phones</MenuItem>
                    <MenuItem value='Tablet'>Tablet</MenuItem>
                  </Select>
                  <FormHelperText>
                    {formik.touched.device && formik.errors.device}
                  </FormHelperText>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <div className={classes.inputwrap}>
                <InputLabel className='Mui-sideLabel'>End Date</InputLabel>
                <KeyboardDatePicker
                  disableFuture
                  variant='inline'
                  inputVariant='outlined'
                  format='MM/dd/yyyy'
                  margin='dense'
                  minDate={formik.values.startDate?.toFormat('MM/dd/yyyy')}
                  inputValue={
                    !isDateError
                      ? formik.values.endDate.toFormat('MM/dd/yyyy')
                      : ''
                  }
                  value={formik.values.endDate}
                  InputAdornmentProps={{ position: 'start' }}
                  onAccept={onDateAccept}
                  onError={onDateError}
                  onChange={(date) => {
                    formik.handleChange({
                      target: {
                        value: '',
                        name: 'predefinedDates',
                      },
                    });

                    formik.handleChange({
                      target: {
                        value: date as luxon.DateTime,
                        name: 'endDate',
                      },
                    });
                  }}
                />
              </div>
              <div className={classes.inputwrap}>
                <InputLabel className='Mui-sideLabel'>Market</InputLabel>
                <FormControl
                  variant='outlined'
                  margin='dense'
                  error={formik.touched.market && Boolean(formik.errors.market)}
                >
                  <Select
                    value={formik.values.market}
                    onChange={formik.handleChange}
                    name='market'
                  >
                    {marketConstants.map((x) => (
                      <MenuItem key={x.value} value={x.value}>
                        {x.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.market && formik.errors.market}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className={classes.inputwrap}>
                <InputLabel className='Mui-sideLabel'>Source</InputLabel>
                <FormControl
                  variant='outlined'
                  margin='dense'
                  error={formik.touched.source && Boolean(formik.errors.source)}
                >
                  <Select
                    value={formik.values.source}
                    onChange={formik.handleChange}
                    name='source'
                  >
                    <MenuItem value='all'>all</MenuItem>
                    {filterOptions?.sources?.map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.source && formik.errors.source}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className={classes.inputwrap}>
                <InputLabel className='Mui-sideLabel'>User Country</InputLabel>
                <Autocomplete
                  fullWidth
                  value={formik.values.country}
                  options={countries.map((x) => x.name)}
                  onChange={(e, value: string | null) =>
                    formik.handleChange({ target: { name: 'country', value } })
                  }
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <FormControl
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      error={
                        formik.touched.country && Boolean(formik.errors.country)
                      }
                    >
                      <TextField
                        {...params}
                        variant='outlined'
                        inputProps={{
                          ...params.inputProps,
                          className: 'Mui-basicInput',
                        }}
                      />
                      <FormHelperText>
                        {formik.touched.country && formik.errors.country}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </div>
              <div>
                <Button
                  variant='contained'
                  color='primary'
                  type='submit'
                  className='Mui-filtersSave'
                >
                  Save
                </Button>
                {reports.length > 0 && (
                  <Button
                    variant='contained'
                    color='secondary'
                    className='Mui-filtersCSV'
                  >
                    <CSVLink
                      data={reports}
                      headers={csvHeaders}
                      className={classes.csvLink}
                    >
                      CSV Download
                    </CSVLink>
                  </Button>
                )}
                <Button
                  onClick={handleResetFilters}
                  variant='contained'
                  type='submit'
                  className='Mui-reset'
                >
                  Reset
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Accordion>
    </Box>
  );
};

export default FilterOptions;
