import Immutable from 'seamless-immutable';
import * as types from './actionTypes';
import { IAuthStoreState } from 'src/types/AuthStoreState';
import * as _ from 'lodash';

const emptyState: IAuthStoreState = {
  isWaiting: true,
  isAuthenticated: false,
  loggedInUser: null,
};

const initialState = Immutable(_.cloneDeep(emptyState));

export default function reduce(state = initialState, action: any = {}): any {
  switch (action.type) {
    case types.LOGIN_ERROR:
      return { ...state, isWaiting: false, isAuthenticated: false };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isWaiting: false,
        isAuthenticated: true,
        loggedInUser: action.loggedInUser,
      };

    case types.LOGOUT_USER:
      return { ...state, isAuthenticated: false, loggedInUser: null };

    case types.SET_WAITING:
      return { ...state, isWaiting: action.payload };

    default:
      return state;
  }
}
