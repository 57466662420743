import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { History } from 'history';
import ExitIcon from '@material-ui/icons/ExitToApp';
import AdminIcon from '@material-ui/icons/SupervisorAccount';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { logout } from 'src/store/auth/actions';
import { AllState } from 'src/types/AllState';
import { Role, roles } from 'src/types/Role';
import {
  Avatar,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh',
    width: 'calc(100vw - 240px)',
  },
}));

export const LoggedInLayout: React.FC = ({ children }) => {
  const classes = useStyles();
  const history: History = useHistory();
  const dispatch = useDispatch();

  const { loggedInUser } = useSelector(({ auth }: AllState) => auth);

  const handleLogout = useCallback(async () => {
    await dispatch(logout());
    history.push('/');
  }, [history, dispatch]);

  const drawer = useMemo(
    () => (
      <div className={classes.drawerContent}>
        <div>
          <ListItem>
            <ListItemIcon>
              <Avatar src={loggedInUser?.picture}>
                {loggedInUser?.name?.slice(0, 1).toUpperCase()}
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={loggedInUser?.name}
              secondary={
                loggedInUser?.role ? roles[loggedInUser?.role - 1] : null
              }
            />
          </ListItem>
          <Divider />
          <ListItem button component={Link} to='/dashboard'>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary='Dashboard' />
          </ListItem>
          <Divider />
          {loggedInUser?.role === Role.admin && (
            <ListItem button component={Link} to='/admin'>
              <ListItemIcon>
                <AdminIcon />
              </ListItemIcon>
              <ListItemText primary='Admin' />
            </ListItem>
          )}
        </div>
        <List>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitIcon />
            </ListItemIcon>
            <ListItemText primary='Sign out' />
          </ListItem>
        </List>
      </div>
    ),
    [handleLogout, loggedInUser, classes]
  );

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant='permanent'
          open
        >
          {drawer}
        </Drawer>
      </nav>
      <main className={classes.content}>{children}</main>
    </div>
  );
};
