import * as _ from 'lodash';
import * as types from './actionTypes';
import Immutable from 'seamless-immutable';
import { ISocialMediasStoreState } from 'src/types/DataStoreStates';
import { getInitialMeta } from 'src/types/MetaData';

const socialMediasStoreInitialMeta = getInitialMeta({ sort: 'name', limit: 5 });

const emptyState: ISocialMediasStoreState = {
  isWaiting: true,
  data: [],
  meta: socialMediasStoreInitialMeta,
};

const initialState = Immutable(_.cloneDeep(emptyState));

export default function reduce(state = initialState, action: any = {}): any {
  switch (action.type) {
    case types.GET_SOCIAL_MEDIAS_SUCCESS:
      return {
        ...state,
        isWaiting: false,
        ...action.payload,
      };

    case types.SET_WAITING:
      return { ...state, isWaiting: action.payload };

    case types.APPEND_META:
      return { ...state, meta: { ...state.meta, ...action.payload } };

    default:
      return state;
  }
}
