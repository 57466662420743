import { AxiosResponse } from 'axios';
import * as types from './actionTypes';
import UsersService from 'src/services/users';
import { User } from 'src/models';
import { IMetaData } from 'src/types/MetaData';
import { AllState } from 'src/types/AllState';
import { roles } from 'src/types/Role';

export function setWaiting(val: boolean) {
  return {
    type: types.SET_WAITING,
    payload: val,
  };
}

export function deleteUser(
  id: string
): (dispatch: any, getState: any) => Promise<boolean> {
  return async (dispatch: any, getState: any): Promise<boolean> => {
    try {
      await UsersService.deleteUser(id);

      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };
}

export function getUsers(
  meta?: Partial<IMetaData>
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const { users }: AllState = getState();
      const metaData: IMetaData = { ...users.meta, ...(meta ?? {}) };

      await dispatch({
        type: types.SET_WAITING,
        payload: true,
      });

      await dispatch({
        type: types.APPEND_META,
        payload: metaData,
      });

      const axiosResponse: AxiosResponse = await UsersService.getUsers(
        metaData
      );

      await dispatch({
        type: types.GET_USERS_SUCCESS,
        payload: {
          data: axiosResponse.data.users.rows.map((x: any) => new User(x)),
          meta: axiosResponse.data.users.meta,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch({
        type: types.SET_WAITING,
        payload: false,
      });
    }
  };
}

export function createUser(
  data: Partial<User>
): (dispatch: any, getState: any) => Promise<string | null> {
  return async (dispatch: any, getState: any): Promise<string | null> => {
    try {
      const role = data.role ? roles[data.role - 1] : undefined;

      await UsersService.createUser({ ...data, role });

      return null;
    } catch (error) {
      console.log(error);

      const { response } = error;
      const data = response?.data;

      const errorMessage =
        data?.message ??
        'A server error has occured while attempting to create user.';

      return data?.errors ?? errorMessage;
    }
  };
}

export function updateUser(
  data: Partial<User>
): (dispatch: any, getState: any) => Promise<string | null> {
  return async (dispatch: any, getState: any): Promise<string | null> => {
    try {
      const role = data.role ? roles[data.role - 1] : undefined;

      await UsersService.updateUser({
        id: data.id,
        name: data.name,
        email: data.email,
        role,
      });

      return null;
    } catch (error) {
      console.log(error);

      const { response } = error;
      const data = response?.data;

      const errorMessage =
        data?.message ??
        'A server error has occured while attempting to update user.';

      return data?.errors ?? errorMessage;
    }
  };
}
