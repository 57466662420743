import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TableContainer,
  TablePagination,
  TableSortLabel,
  LinearProgress,
} from '@material-ui/core';
import ActionButtons from './ActionButtons';
import { IMetaData } from 'src/types/MetaData';
import TablePaginationActions from 'src/components/table/TablePaginationsActions';

interface Props<T> {
  data: T[];
  headerCells: {
    key: keyof T;
    label: string;
  }[];
  meta: IMetaData;
  isLoading: boolean;
  dispatchForGetData?: boolean;
  getData: (meta?: Partial<IMetaData>) => void;
  useActions?: boolean;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  renderCell?: (prop: keyof T, row: T) => any;
}

function DataTable<T>({
  data,
  meta,
  isLoading,
  headerCells,
  useActions,
  getData,
  onEdit,
  onDelete,
  renderCell: customRenderCell,
  dispatchForGetData = true,
}: React.PropsWithChildren<Props<T>>) {
  const dispatch = useDispatch();

  const onGetData = useCallback(
    (meta?: Partial<IMetaData>) => {
      if (dispatchForGetData) {
        dispatch(getData(meta));
      } else {
        getData(meta);
      }
    },
    [dispatchForGetData, dispatch, getData]
  );

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      onGetData({ page: newPage });
    },
    [onGetData]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onGetData({ page: 0, limit: parseInt(event.target.value, 10) });
    },
    [onGetData]
  );

  const handleSortClick = useCallback(
    (propName: string) => {
      const isAsc = meta.sort === propName && meta.order === 'asc';

      onGetData({ sort: propName, order: isAsc ? 'desc' : 'asc' });
    },
    [meta, onGetData]
  );

  const renderCell = useCallback(
    (prop: keyof T, row: T) => {
      return customRenderCell ? customRenderCell(prop, row) : row[prop];
    },
    [customRenderCell]
  );

  return (
    <TableContainer component={Paper}>
      {isLoading && <LinearProgress />}
      <Table>
        <TableHead>
          <TableRow>
            {headerCells.map((x) => (
              <TableCell
                className='Mui-reportCell'
                key={String(x.key)}
                component='th'
                scope='row'
                sortDirection={meta.order}
              >
                <TableSortLabel
                  active={x.key === meta.sort}
                  direction={meta.order}
                  onClick={() => handleSortClick(x.key.toString())}
                >
                  {x.label}
                </TableSortLabel>
              </TableCell>
            ))}
            {useActions && !!onEdit && !!onDelete && (
              <TableCell align='right'></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, idx) => (
            <TableRow key={idx}>
              {headerCells.map((x) => (
                <TableCell
                  key={String(x.key)}
                  className='Mui-reportCell'
                  align='left'
                >
                  {renderCell(x.key, row)}
                </TableCell>
              ))}
              {useActions && !!onEdit && !!onDelete && (
                <TableCell align='right'>
                  <ActionButtons
                    id={row['id' as keyof T] as any}
                    onEdit={onEdit}
                    onDelete={onDelete}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              classes={{ toolbar: 'Mui-dataTablePagination' }}
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={useActions ? headerCells.length + 1 : headerCells.length}
              rowsPerPage={meta.limit}
              count={meta.totalItems}
              page={meta.page}
              SelectProps={{
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default DataTable;
