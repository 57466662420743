export class Partner {
  public id?: string;
  public name: string;
  public createdAt?: string;
  public updatedAt?: string;

  public constructor(props: any) {
    Object.assign(this, props);
  }
}
