import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import {
  getSocialMedias,
  deleteSocialMedia,
} from 'src/store/socialMedias/actions';
import { AllState } from 'src/types/AllState';
import SocialMediasTable from './SocialMediasTable';
import { IMetaData } from 'src/types/MetaData';
import { toast } from 'react-toastify';
import SocialMediaForm from 'src/components/forms/SocialMediaForm';
import { SocialMedia } from 'src/models';

const SocialMediasManagement: React.FC = () => {
  const dispatch = useDispatch();

  const { data: socialMedias, meta, isWaiting } = useSelector(
    ({ socialMedias }: AllState) => ({
      ...socialMedias,
    })
  );

  const [isFormOpen, setFormOpen] = useState<boolean>(false);
  const [
    currentSocialMedia,
    setCurrentSocialMedia,
  ] = useState<SocialMedia | null>(null);

  useEffect(() => {
    dispatch(getSocialMedias(meta));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isFormOpen) {
      setCurrentSocialMedia(null);
    }
  }, [isFormOpen]);

  const handleGetSocialMedias = useCallback(
    (metaData?: Partial<IMetaData>) => {
      dispatch(getSocialMedias(metaData));
    },
    [dispatch]
  );

  const handleDeleteSocialMedia = useCallback(
    async (id: string) => {
      if (await dispatch(deleteSocialMedia(id))) {
        await dispatch(getSocialMedias());
        toast.success('Social media was deleted successfully!');
      }
    },
    [dispatch]
  );

  const handleEditSocialMedia = useCallback(
    async (id: string) => {
      const socialMedia = socialMedias.find((x) => x.id === id);
      if (socialMedia) {
        setCurrentSocialMedia(socialMedia);
        setFormOpen(true);
      }
    },
    [setCurrentSocialMedia, setFormOpen, socialMedias]
  );

  return (
    <Box py={3}>
      <Typography variant='h6'>Top social medias management</Typography>
      <Box pt={2} pb={3}>
        <Button
          variant='contained'
          color='primary'
          onClick={() => setFormOpen(true)}
        >
          Add new
        </Button>
      </Box>

      <Box maxWidth={1200}>
        <SocialMediasTable
          socialMedias={socialMedias}
          meta={meta}
          isLoading={isWaiting}
          getData={getSocialMedias}
          onDelete={handleDeleteSocialMedia}
          onEdit={handleEditSocialMedia}
        />
      </Box>

      <SocialMediaForm
        open={isFormOpen}
        handleClose={() => setFormOpen(false)}
        getSocialMedias={handleGetSocialMedias}
        socialMedia={currentSocialMedia}
      />
    </Box>
  );
};

export default SocialMediasManagement;
