import React from 'react';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import TableChartIcon from '@material-ui/icons/TableChart';
import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import Accordion from 'src/components/containers/Accordion/Accordion';
import {
  Area,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { AllState } from 'src/types/AllState';

const useStyles = makeStyles(() => ({
  chartWrap: {
    width: '100%',
    height: '400px',
    marginBottom: '15px',
  },
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const RevenueGraph: React.FC = () => {
  const classes = useStyles();
  const theme: any = useTheme();
  const state = useSelector((state: AllState) => state);
  const { isWaiting: isLoading, meta, reports } = state.reports;

  if (isLoading || reports == null) {
    return <div>Run a report to see the revenue graph.</div>;
  } else if (meta.totalItems === 0) {
    // Don't write anything because another object will write it.
    return <div></div>;
  }

  var dates = _.groupBy(reports, 'date');
  var dataPoints = Object.keys(dates).map((date: string) => {
    return {
      date,
      revenue: _.sumBy(dates[date], 'revenue_usd'),
    };
  });

  const data = {
    totalRevenue: _.sumBy(reports, 'revenue_usd'),
    chart: _.sortBy(dataPoints, 'date'),
  };

  return (
    <Box maxWidth={1280} pt={4}>
      <Accordion headerLabel='Revenue Graph' headerIcon={TableChartIcon}>
        <div className={classes.root}>
          <div className={classes.chartWrap}>
            <ResponsiveContainer width='100%' height='100%'>
              <AreaChart
                data={data.chart}
                margin={{
                  top: 20,
                  right: 60,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='date' />
                <YAxis
                  type='number'
                  tickCount={8}
                  domain={[0, (dataMax: number) => dataMax * 1.25]}
                />
                <Tooltip />
                <Area
                  type='monotone'
                  dataKey='revenue'
                  stroke={theme.palette.primary.darker}
                  fill={theme.palette.primary.lighter}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <Typography variant='body2'>
            Total revenue in period: <b>${data.totalRevenue}</b>
          </Typography>
        </div>
      </Accordion>
    </Box>
  );
};

export default RevenueGraph;
