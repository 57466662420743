import {
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';

import TocIcon from '@material-ui/icons/Toc';
import Accordion from 'src/components/containers/Accordion/Accordion';
import React, { useEffect, useState } from 'react';
import ReportingService from '../../../../services/reports';

const AdoptionTable: React.FC = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    ReportingService.getAdoptionReport().then((axiosData: any) => {
      setData(axiosData.data.rows);
    });
  }, []);

  const headerCells = [{ key: 'date', label: 'Date' }, { key: 'action', label: 'Action' }, { key: 'partnerId', label: 'PartnerId' }];

  return (
    <Grid item xs={4}>
      <Accordion headerLabel='Adoptions in last 30 days' headerIcon={TocIcon}>
        <TableContainer component={Paper}>
          {data.length === 0 && <LinearProgress />}
          <Table>
            <TableHead>
              <TableRow>
                {headerCells.map((x: any) => (
                  <TableCell
                    className='Mui-reportCell'
                    key={x.key}
                    component='th'
                    scope='row'
                  >
                    {x.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row: any, idx: any) => (
                <TableRow key={idx}>
                  {headerCells.map((x: any) => (
                    <TableCell
                      className='Mui-reportCell'
                      align='left'
                      key={x.key}
                    >
                      {row[x.key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
            </TableFooter>
          </Table>
        </TableContainer>
      </Accordion>
    </Grid>
  );
};

export default AdoptionTable;
