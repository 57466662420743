import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  IconButton,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Accordion as MuiAccordion,
} from '@material-ui/core';

interface Props {
  headerIcon?: any;
  headerLabel: string;
  defaultExpanded?: boolean;
  children: React.ReactNode;
}

const Accordion: React.FC<Props> = ({
  children,
  headerIcon,
  headerLabel,
  defaultExpanded = true,
}) => {
  const HeaderIcon = headerIcon;

  return (
    <MuiAccordion defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <FormControlLabel
          classes={{ label: 'Mui-accordionLabel' }}
          control={
            HeaderIcon ? (
              <IconButton className='Mui-accordionHead'>
                <HeaderIcon fontSize='small' />
              </IconButton>
            ) : (
              <></>
            )
          }
          label={headerLabel}
        />
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
