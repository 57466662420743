export enum Role {
  admin = 1,
  user,
  agent,
  partner,
  webstar,
}

export const roles = (Object.keys(Role) as Array<keyof typeof Role>).filter(
  (i) => typeof Role[i] === 'number'
);
