import { AxiosResponse } from 'axios';
import { IMetaData } from 'src/types/MetaData';
import { metaToQueryParams } from 'src/utils/meta';
import api from './api';

class PromotionsService {
  public async getPartners(): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/partners',
    });
  }

  public async getPromotions(meta?: IMetaData): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/promotions' + (meta ? metaToQueryParams(meta) : ''),
    });
  }

  public async createPromotion(promotion: any): Promise<AxiosResponse> {
    return await api({
      method: 'post',
      url: '/api/promotion',
      data: promotion,
    });
  }

  public async updatePromotion(promotion: any): Promise<AxiosResponse> {
    return await api({
      method: 'put',
      url: `/api/promotion/${promotion.id}`,
      data: promotion,
    });
  }

  public async deletePromotion(id: string): Promise<AxiosResponse> {
    return await api({
      method: 'delete',
      url: `/api/promotion/${id}`,
    });
  }
}

export default new PromotionsService();
