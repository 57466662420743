import axios, { AxiosResponse } from 'axios';
import api from './api';

class AuthService {
  public async register(data: any): Promise<AxiosResponse> {
    return await api({
      method: 'post',
      url: '/api/sign-up',
      data: data,
    });
  }

  public async login(data: any): Promise<AxiosResponse> {
    return await api({
      method: 'post',
      url: '/api/sign-in',
      data: data,
    });
  }

  public async googleAuth(access_token: string): Promise<AxiosResponse> {
    return await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/auth/google`,
      headers: {
        Authorization: access_token,
      },
    });
  }

  public async facebookAuth(access_token: string): Promise<AxiosResponse> {
    return await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/api/auth/facebook`,
      headers: {
        Authorization: access_token,
      },
    });
  }

  public async getUserData(): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/user',
    });
  }
}

export default new AuthService();
