import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const base = createMuiTheme();

const palette = {
  primary: {
    main: '#50b6e6',
    dark: '#43adde',
    darker: '#0979ad',
    light: '#69c8f5',
    lighter: '#abe0ff',
  },
  secondary: {
    light: '#95e305',
    main: '#82c703',
    dark: '#7aba02',
  },
};

const theme = {
  palette,
  overrides: {
    MuiPaper: {
      root: {
        '&.Mui-authFormWrap': {
          maxWidth: '300px',
          padding: '25px 35px',
        },
      },
    },
    MuiTableCell: {
      root: {
        '&.Mui-reportCell': {
          padding: '10px',
          borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
      },
      head: {
        fontWeight: 600,
      },
    },
    MuiIconButton: {
      root: {
        '&.Mui-spaceRight10': {
          marginRight: '10px',
        },
        '&.Mui-accordionHead': {
          color: 'white',
          borderRadius: 0,
          padding: '10px',
          userSelect: 'none',
          background: '#419cc5',
        },
      },
    },
    MuiButton: {
      root: {
        color: 'white',
        'text-transform': 'initial',

        '&.Mui-filtersSave': {
          marginTop: '8px',
          minWidth: '80px',
          marginLeft: '30%',
          marginRight: '15px',
          padding: '8px 16px',
        },
        '&.Mui-filtersCSV': {
          marginTop: '8px',
          padding: '8px 16px',
        },
        '&.Mui-reset': {
          marginLeft: '15px',
          marginTop: '8px',
          padding: '8px 16px',
          background: base.palette.error.main,
        },
      },
      label: {
        color: 'white',
        fontWeight: 600,
      },
    },
    MuiAccordion: {
      rounded: {
        '&:first-child': {
          'border-top-left-radius': '2px',
          'border-top-right-radius': '2px',
        },
        '&:last-child': {
          'border-bottom-left-radius': '2px',
          'border-bottom-right-radius': '2px',
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        minHeight: '40px',
        padding: '0px 11px',
        background: palette.primary.main,

        '&.Mui-expanded': {
          minHeight: '40px',
        },
      },
      content: {
        margin: 0,
        color: 'white',

        '&.Mui-expanded': {
          margin: 0,
        },
      },
      expandIcon: {
        color: 'white',
        padding: '8px',
        borderRadius: 0,
        backgroundColor: '#419cc5',
        transition: 'background 0.4s ease-in',

        '&:hover': {
          backgroundColor: '#419cc5',
        },
      },
    },
    MuiTypography: {
      root: {
        '&.Mui-accordionLabel': {
          marginLeft: '20px',
        },
        '&.Mui-modalHeader': {
          marginLeft: '15px',
          marginTop: '7px',
          fontWeight: 600,
        },
      },
    },
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: 0,
      },
      input: {
        '&.Mui-basicInput': {
          padding: '1.5px 5px',
        },
      },
    },
    MuiInputLabel: {
      root: {
        '&.Mui-sideLabel': {
          display: 'flex',
          marginTop: '19px',
          marginRight: '10px',
          minWidth: 'fit-content',
          color: base.palette.text.primary,
          fontSize: '15px',
          fontWeight: 600,
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiTablePagination: {
      toolbar: {
        '&.Mui-dataTablePagination': {
          '& > div:first-child': {
            display: 'none',
          },
        },
      },
    },
    MuiSlider: {
      root: {
        padding: '30px 0 0 0',
      },
    },
  },
};

export default createMuiTheme(theme);
