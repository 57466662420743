import { Paper } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as userActions from 'src/store/auth/actions';
import Form from './Form';

interface IDispatchProps {
  register: (data: any) => Promise<any>;
}

type Props = IDispatchProps;

const SignIn: React.FC<Props> = ({ register }) => {
  return (
    <Paper className='Mui-authFormWrap'>
      <Form register={register} />
    </Paper>
  );
};

function mapDispatchToProps(dispatch: Dispatch) {
  return (bindActionCreators(
    {
      register: userActions.register,
    },
    dispatch
  ) as unknown) as IDispatchProps;
}

export default connect(null, mapDispatchToProps)(SignIn);
