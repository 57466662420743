import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { getPromotions, deletePromotion } from 'src/store/promotions/actions';
import { AllState } from 'src/types/AllState';
import PromotionsTable from './PromotionsTable';
import { IMetaData } from 'src/types/MetaData';
import { toast } from 'react-toastify';
import PromotionForm from 'src/components/forms/PromotionForm';
import { Promotion } from 'src/models';

interface Props {
  type: 'bestofchrome' | 'favorites';
}

const PromotionsManagement: React.FC<Props> = ({ type }) => {
  const dispatch = useDispatch();

  const { data: promotions, meta, isWaiting, loggedInUser } = useSelector(
    ({ promotions, auth }: AllState) => ({
      ...promotions,
      loggedInUser: auth.loggedInUser,
    })
  );

  const [isFormOpen, setFormOpen] = useState<boolean>(false);
  const [currentPromotion, setCurrentPromotion] = useState<Promotion | null>(
    null
  );

  useEffect(() => {
    if (!isFormOpen) {
      setCurrentPromotion(null);
    }
  }, [isFormOpen]);

  const handleGetPromotions = useCallback(
    async (metaData?: Partial<IMetaData>) => {
      await dispatch(
        getPromotions({
          ...(metaData ?? {}),
          queryParams: { promotionType: type },
        })
      );
    },
    [dispatch, type]
  );

  useEffect(() => {
    handleGetPromotions(meta);
    // eslint-disable-next-line
  }, []);

  const handleDeletePromotion = useCallback(
    async (id: string) => {
      if (await dispatch(deletePromotion(id))) {
        await handleGetPromotions();
        toast.success('Promotion was deleted successfully!');
      }
    },
    [dispatch, handleGetPromotions]
  );

  const handleEditPromotion = useCallback(
    async (id: string) => {
      const promotion = promotions.find((x) => x.id === id);
      if (promotion) {
        setCurrentPromotion(promotion);
        setFormOpen(true);
      }
    },
    [setCurrentPromotion, setFormOpen, promotions]
  );

  return (
    <Box py={3}>
      <Typography variant='h6'>
        {type === 'bestofchrome'
          ? 'Best Chrome extensions'
          : 'Favorite websites'}{' '}
        management
      </Typography>
      <Box pt={2} pb={3}>
        <Button
          variant='contained'
          color='primary'
          onClick={() => setFormOpen(true)}
        >
          Add new
        </Button>
      </Box>

      <Box maxWidth={1200}>
        <PromotionsTable
          promotions={promotions}
          meta={meta}
          isLoading={isWaiting}
          getData={handleGetPromotions}
          onDelete={handleDeletePromotion}
          onEdit={handleEditPromotion}
        />
      </Box>

      <PromotionForm
        type={type}
        open={isFormOpen}
        user={loggedInUser}
        handleClose={() => setFormOpen(false)}
        getPromotions={handleGetPromotions}
        promotion={currentPromotion}
      />
    </Box>
  );
};

export default PromotionsManagement;
