export interface ILocalStorage {
  get: (key: string) => any;
  set: (key: string, value: any) => void;
  remove: (key: string) => void;
}

const localStore: ILocalStorage = {
  get(key: string): any {
    const value = localStorage.getItem(key);

    return value ? JSON.parse(value) : null;
  },

  set(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  },

  remove(key: string): void {
    localStorage.removeItem(key);
  },
};

export default localStore;
