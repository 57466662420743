import { AxiosResponse } from 'axios';
import { IMetaData } from 'src/types/MetaData';
import { metaToQueryParams } from 'src/utils/meta';
import api from './api';

class SocialMediasService {
  public async getSocialMedias(meta?: IMetaData): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/social-medias' + (meta ? metaToQueryParams(meta) : ''),
    });
  }

  public async createSocialMedia(socialMedia: any): Promise<AxiosResponse> {
    return await api({
      method: 'post',
      url: '/api/social-media',
      data: socialMedia,
    });
  }

  public async updateSocialMedia(socialMedia: any): Promise<AxiosResponse> {
    return await api({
      method: 'put',
      url: `/api/social-media/${socialMedia.id}`,
      data: socialMedia,
    });
  }

  public async deleteSocialMedia(id: string): Promise<AxiosResponse> {
    return await api({
      method: 'delete',
      url: `/api/social-media/${id}`,
    });
  }
}

export default new SocialMediasService();
