import React, { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { IMetaData } from 'src/types/MetaData';
import { Role } from 'src/types/Role';
import { User } from 'src/models';
import DataTable from 'src/components/table/DataTable';

interface Props {
  users: User[];
  meta: IMetaData;
  isLoading?: boolean;
  getData: (meta?: Partial<IMetaData>) => void;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
}

const UsersTable: React.FC<Props> = ({
  users,
  meta,
  isLoading,
  getData,
  onDelete,
  onEdit,
}) => {
  const renderRow = useCallback((prop: keyof User, row: User) => {
    switch (prop) {
      case 'role':
        return Role[row[prop]]?.toString();
      case 'updatedAt':
      case 'createdAt':
        return DateTime.fromISO(row[prop]?.toString() ?? '').toLocaleString(
          DateTime.DATETIME_SHORT
        );
      default:
        return row[prop];
    }
  }, []);

  const cells: { label: string; key: keyof User }[] = useMemo(
    () => [
      { label: 'Name', key: 'name' },
      { label: 'Email', key: 'email' },
      { label: 'Role', key: 'role' },
      { label: 'Updated at', key: 'updatedAt' },
      { label: 'Created at', key: 'createdAt' },
    ],
    []
  );

  return (
    <DataTable<User>
      useActions
      data={users}
      meta={meta}
      headerCells={cells}
      isLoading={!!isLoading}
      renderCell={renderRow}
      getData={getData}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
};

export default UsersTable;
