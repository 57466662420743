export class SocialMedia {
  public id?: string;
  public partnerId: string;
  public partnerName: string;
  public name: string;
  public url: string;
  public rank: number;
  public createdAt?: string;
  public updatedAt?: string;

  public constructor(props: any) {
    Object.assign(this, props);

    this.partnerName = props.partner?.name;
  }
}
