import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

interface Props {
  id: string | undefined;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

const ActionButtons: React.FC<Props> = ({ id, onEdit, onDelete }) => {
  return (
    <Grid container wrap='nowrap' justify='flex-end'>
      <Grid item>
        <IconButton
          size='small'
          className='Mui-spaceRight10'
          onClick={() => {
            if (id) {
              onEdit(id);
            }
          }}
        >
          <EditIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          size='small'
          onClick={() => {
            if (id) {
              onDelete(id);
            }
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ActionButtons;
