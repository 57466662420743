import { AxiosResponse } from 'axios';
import { IMetaData } from 'src/types/MetaData';
import { metaToQueryParams } from 'src/utils/meta';
import api from './api';

class UsersService {
  public async getUsers(meta?: IMetaData): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/users' + (meta ? metaToQueryParams(meta) : ''),
    });
  }

  public async createUser(user: any): Promise<AxiosResponse> {
    return await api({
      method: 'post',
      url: '/api/user',
      data: user,
    });
  }

  public async updateUser(user: any): Promise<AxiosResponse> {
    return await api({
      method: 'put',
      url: `/api/user/${user.id}`,
      data: user,
    });
  }

  public async deleteUser(id: string): Promise<AxiosResponse> {
    return await api({
      method: 'delete',
      url: `/api/user/${id}`,
    });
  }
}

export default new UsersService();
