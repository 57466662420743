import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from '../../views/Dashboard';
import Admin from '../../views/Admin';
import NotFound from '../../views/Error';

const AppContent: React.FC = () => {
  return (
    <Switch>
      <Route exact path='/dashboard' component={Dashboard} />
      <Route exact path='/admin' component={Admin} />

      <Route
        exact
        path='/'
        render={(props) => <Redirect to='/dashboard' {...props} />}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default AppContent;
