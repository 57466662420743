import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AllState } from 'src/types/AllState';
import { LoggedInLayout, LoggedOutLayout } from '../../layout';

interface IOwnProps {
  component: React.FC<any>;
}

type Props = IOwnProps & RouteProps;

//  -- Unauthorized route --
const UnauthorizedRoute: React.FC<Props> = ({
  component: Component,
  ...rest
}) => {
  const isLoggedIn = useSelector(({ auth }: AllState) => auth.isAuthenticated);
  // const isLoggedIn = true;
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn && props.location.pathname !== '/reset-password' ? (
          <Redirect to='/dashboard' />
        ) : (
          <LoggedOutLayout>
            <Component {...props} />
          </LoggedOutLayout>
        )
      }
    />
  );
};

// -- Private route --
const PrivateRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(({ auth }: AllState) => auth.isAuthenticated);

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn ? (
          <Redirect
            to={{ pathname: '/sign-in', state: { from: props.location } }}
          />
        ) : (
          <LoggedInLayout>
            <Component {...props} />
          </LoggedInLayout>
        )
      }
    />
  );
};

export { UnauthorizedRoute, PrivateRoute };
