import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import localStore from 'src/services/localStore';
import { getUserData, setWaiting } from 'src/store/auth/actions';
import { AllState } from 'src/types/AllState';
import { IAuthStoreState } from 'src/types/AuthStoreState';

const useUserData = (): IAuthStoreState => {
  const dispatch = useDispatch();

  const authState = useSelector(({ auth }: AllState) => auth);

  const getUser = useCallback(async () => {
    if (!!(await localStore.get('token'))) {
      dispatch(getUserData());
    } else {
      dispatch(setWaiting(false));
    }
  }, [dispatch]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  return { ...authState };
};

export default useUserData;
