import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { getUsers, deleteUser } from 'src/store/users/actions';
import { AllState } from 'src/types/AllState';
import UsersTable from './UsersTable';
import { IMetaData } from 'src/types/MetaData';
import { toast } from 'react-toastify';
import UserForm from 'src/components/forms/UserForm';
import { User } from 'src/models';

const UsersManagement: React.FC = () => {
  const dispatch = useDispatch();

  const { data: users, meta, isWaiting } = useSelector(
    ({ users }: AllState) => ({
      ...users,
    })
  );

  const [isFormOpen, setFormOpen] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  useEffect(() => {
    dispatch(getUsers(meta));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isFormOpen) {
      setCurrentUser(null);
    }
  }, [isFormOpen]);

  const handleGetUsers = useCallback(
    (metaData?: Partial<IMetaData>) => {
      dispatch(getUsers(metaData));
    },
    [dispatch]
  );

  const handleDeleteUser = useCallback(
    async (id: string) => {
      if (await dispatch(deleteUser(id))) {
        await dispatch(getUsers());
        toast.success('User was deleted successfully!');
      }
    },
    [dispatch]
  );

  const handleEditUser = useCallback(
    async (id: string) => {
      const user = users.find((x) => x.id === id);
      if (user) {
        setCurrentUser(user);
        setFormOpen(true);
      }
    },
    [setCurrentUser, setFormOpen, users]
  );

  return (
    <Box py={3}>
      <Typography variant='h6'>Users management</Typography>
      <Box pt={2} pb={3}>
        <Button
          variant='contained'
          color='primary'
          onClick={() => setFormOpen(true)}
        >
          Add new
        </Button>
      </Box>

      <Box maxWidth={1200}>
        <UsersTable
          users={users}
          meta={meta}
          isLoading={isWaiting}
          getData={getUsers}
          onDelete={handleDeleteUser}
          onEdit={handleEditUser}
        />
      </Box>

      <UserForm
        open={isFormOpen}
        handleClose={() => setFormOpen(false)}
        getUsers={handleGetUsers}
        user={currentUser}
      />
    </Box>
  );
};

export default UsersManagement;
