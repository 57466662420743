import { ReportItem } from 'src/models';

const headers: { label: string; key: keyof ReportItem }[] = [
  { label: 'Date', key: 'date' },
  { label: 'Search Type', key: 'search_type' },
  { label: 'Source', key: 'source' },
  { label: 'Market', key: 'market' },
  { label: 'User country', key: 'user_country' },
  { label: 'Device', key: 'device' },
  { label: 'Product', key: 'product' },
  { label: 'Searches', key: 'searches' },
  { label: 'Bidded searches', key: 'bidded_searches' },
  { label: 'Bidded clicks', key: 'bidded_clicks' },
  { label: 'Bidded results', key: 'bidded_results' },
  { label: 'TQ score', key: 'tq_score' },
  { label: 'Revenue VMN (USD)', key: 'revenue_usd' },
];

export default headers;
