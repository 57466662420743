import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { AllState } from 'src/types/AllState';

const AffiliateLink: React.FC = () => {
  const { loggedInUser } = useSelector(({ auth }: AllState) => ({
    loggedInUser: auth.loggedInUser,
  }));

  if (!loggedInUser || !loggedInUser.partnerName) {
    return <></>;
  }

  return (
    <Typography variant='subtitle1'>
      <a
        href={`${
          process.env.REACT_APP_API_BASE_URL
        }/download?ref=${encodeURIComponent(loggedInUser?.partnerName)}`}
        target='_blank'
        rel='noreferrer'
      >
        affiliate link
      </a>
    </Typography>
  );
};

export default AffiliateLink;
