import { IMetaData } from 'src/types/MetaData';

export const metaToQueryParams = (meta: IMetaData): string => {
  let query = `?limit=${meta.limit}&sort=${meta.sort}&order=${meta.order}&page=${meta.page}`;

  if (meta.queryParams) {
    const params = Object.entries(meta.queryParams);

    params.forEach(([key, value]) => {
      if (!!value) {
        query += `&${key}=${value}`;
      }
    });
  }

  return query;
};
