import { AxiosResponse } from 'axios';
import * as types from './actionTypes';
import AuthService from 'src/services/auth';
import { User } from 'src/models';
import localStore from 'src/services/localStore';

export function setWaiting(val: boolean) {
  return {
    type: types.SET_WAITING,
    payload: val,
  };
}

export function register(
  data: Partial<User>
): (dispatch: any, getState: any) => Promise<string | null> {
  return async (dispatch: any, getState: any): Promise<string | null> => {
    try {
      const axiosResponse: AxiosResponse = await AuthService.register(data);

      localStore.set('token', axiosResponse.data.token);

      await dispatch({
        type: types.LOGIN_SUCCESS,
        loggedInUser: new User(axiosResponse.data.user),
      });

      return null;
    } catch (error) {
      dispatch({
        type: types.LOGIN_ERROR,
      });

      const { response } = error;
      const data = response?.data;

      const errorMessage =
        data?.message ??
        'A server error has occured while attempting to login.';

      return data?.errors ?? errorMessage;
    }
  };
}

export function login(
  data: Partial<User>
): (dispatch: any, getState: any) => Promise<string | null> {
  return async (dispatch: any, getState: any): Promise<string | null> => {
    try {
      const axiosResponse: AxiosResponse = await AuthService.login(data);

      localStore.set('token', axiosResponse.data.token);

      await dispatch({
        type: types.LOGIN_SUCCESS,
        loggedInUser: new User(axiosResponse.data.user),
      });

      return null;
    } catch (error) {
      dispatch({
        type: types.LOGIN_ERROR,
      });

      const { response } = error;
      const data = response?.data;

      const errorMessage =
        data?.message ??
        (response?.status === 401
          ? 'Incorrect email/password combination.'
          : 'A server error has occured while attempting to login.');

      return data?.errors ?? errorMessage;
    }
  };
}

export function logout(): (dispatch: any, getState: any) => Promise<any> {
  return async (dispatch: any, getState: any): Promise<any> => {
    localStore.remove('token');
    await dispatch({ type: types.LOGOUT_USER });
  };
}

export function socialAuth(
  access_token: string,
  socialType: 'google' | 'facebook'
): (dispatch: any, getState: any) => Promise<any> {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const authFunc =
        socialType === 'google'
          ? AuthService.googleAuth
          : AuthService.facebookAuth;

      const axiosResponse: AxiosResponse = await authFunc(access_token);

      localStore.set('token', axiosResponse.data.token);

      await dispatch({
        type: types.LOGIN_SUCCESS,
        loggedInUser: new User(axiosResponse.data.user),
      });

      return null;
    } catch (e) {
      dispatch({
        type: types.LOGIN_ERROR,
      });

      return (
        e?.response?.data?.message ??
        'A server error has occured while attempting to login.'
      );
    }
  };
}

export function getUserData(): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const axiosResponse: AxiosResponse = await AuthService.getUserData();

      await dispatch({
        type: types.SET_WAITING,
        payload: true,
      });

      await dispatch({
        type: types.LOGIN_SUCCESS,
        loggedInUser: new User(axiosResponse.data.user),
      });
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch({
        type: types.SET_WAITING,
        payload: false,
      });
    }
  };
}
