import React, { useCallback, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import CancelIcon from '@material-ui/icons/Cancel';
import { IconButton, Typography } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalLeft: {
    justifyContent: 'flex-start',
  },
  modalRight: {
    justifyContent: 'flex-end',
  },
  paper: {
    outline: 'none',
    borderRadius: '3px',
    backgroundColor: '#8c8c8ccc',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(8, 1, 0),
    width: '350px',
    overflowY: 'scroll',
    maxHeight: '100vh',
    position: 'relative',
  },
  whiteBackground: {
    background: '#fffffff0',
  },
  paperToSide: {
    height: '100vh',
    overflowY: 'scroll',
    borderRadius: 0,
  },
  header: {
    position: 'absolute',
    left: '5px',
    top: '5px',
  },
  closeButton: {
    position: 'absolute',
    right: '5px',
    top: '5px',
  },
}));

interface Props {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  header?: React.ReactNode;
  useBackdrop?: boolean;
  attachedToLeft?: boolean;
  attachedToRight?: boolean;
  whiteBackground?: boolean;
}

const TransitionsModal: React.FC<Props> = ({
  open,
  handleClose,
  children,
  attachedToLeft,
  attachedToRight,
  useBackdrop = true,
  header,
  whiteBackground = true,
}) => {
  const classes = useStyles();

  const content = useMemo(() => {
    return (
      <div
        className={classNames(
          classes.paper,
          { [classes.whiteBackground]: whiteBackground },
          {
            [classes.paperToSide]: attachedToLeft || attachedToRight,
          }
        )}
      >
        <div className={classes.header}>
          {typeof header === 'string' ? (
            <Typography variant='h6' className='Mui-modalHeader'>
              {header}
            </Typography>
          ) : (
            header
          )}
        </div>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CancelIcon className={!whiteBackground ? 'Mui-whiteIcon' : ''} />
        </IconButton>
        {children}
      </div>
    );
  }, [
    children,
    header,
    classes,
    handleClose,
    whiteBackground,
    attachedToLeft,
    attachedToRight,
  ]);

  const getContent = useCallback(() => {
    return attachedToLeft || attachedToRight ? (
      <Slide
        direction={attachedToLeft ? 'right' : 'left'}
        in={open}
        mountOnEnter
        unmountOnExit
      >
        {content}
      </Slide>
    ) : (
      <Fade in={open}>{content}</Fade>
    );
  }, [attachedToLeft, attachedToRight, content, open]);

  return (
    <div>
      <Modal
        className={classNames(classes.modal, {
          [classes.modalLeft]: attachedToLeft,
          [classes.modalRight]: attachedToRight,
        })}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          invisible: !useBackdrop,
        }}
      >
        {getContent()}
      </Modal>
    </div>
  );
};

export default TransitionsModal;
