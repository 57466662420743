import { AxiosResponse } from 'axios';
import * as types from './actionTypes';
import ReportsService from 'src/services/reports';
import { ReportItem } from 'src/models';
import { IMetaData } from 'src/types/MetaData';
import { AllState } from 'src/types/AllState';
import { ReportFilterOptions } from 'src/types/ReportsStoreState';

export function getReports(
  meta?: Partial<IMetaData>
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const { reports }: AllState = getState();
      const metaData: IMetaData = { ...reports.meta, ...(meta ?? {}) };

      await dispatch({
        type: types.SET_WAITING,
        payload: true,
      });

      await dispatch({
        type: types.APPEND_META,
        payload: metaData,
      });

      const axiosResponse: AxiosResponse = await ReportsService.getReports(
        metaData
      );

      await dispatch({
        type: types.GET_REPORTS_SUCCESS,
        payload: {
          reports: axiosResponse.data.rows.map((x: any) => new ReportItem(x)),
          meta: { ...metaData, ...(axiosResponse.data.meta ?? {}) },
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch({
        type: types.SET_WAITING,
        payload: false,
      });
    }
  };
}

export function getFilterOptions(): (
  dispatch: any,
  getState: any
) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const {
        data,
      }: AxiosResponse = await ReportsService.getReportFilterOptions();

      const payload = data?.filters
        ? (data.filters as ReportFilterOptions)
        : null;

      await dispatch({
        type: types.SET_FILTER_OPTIONS,
        payload,
      });
    } catch (error) {
      console.log(error);
    }
  };
}
