import { Paper } from '@material-ui/core';
import React from 'react';
import Form from './Form';
import {AxiosResponse} from "axios";
import api from "../../../services/api";

const Reset = (props: any) => {
  // Cho nay can link den ApI
  async function reset(data: any): Promise<AxiosResponse> {
    return api({
      method: 'post',
      url: '/api/reset-password',
      data: data,
    });
  }

  return (
    <Paper className='Mui-authFormWrap'>
      <Form reset={reset} />
    </Paper>
  );
};



export default Reset;
