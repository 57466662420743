import React from 'react';
import { useSelector } from 'react-redux';
import { getReports } from 'src/store/reports/actions';
import { AllState } from 'src/types/AllState';
import TocIcon from '@material-ui/icons/Toc';
import { csvHeaders as headerCells } from 'src/constants';
import DataTable from '../../../table/DataTable';
import { ReportItem } from 'src/models';
import { Box } from '@material-ui/core';
import Accordion from 'src/components/containers/Accordion/Accordion';

const YahooReportDataTable: React.FC = () => {
  const state = useSelector((state: AllState) => state);
  const { isWaiting: isLoading, meta, reports } = state.reports;

  if (!reports) {
    return <div>Run a report to see the data table.</div>;
  } else if (meta.totalItems === 0) {
    return <div style={{ paddingTop: '1em', marginBottom: '1em' }}>No Yahoo reporting data to show for this time range.</div>;
  }

  return (
    <Box maxWidth={1280} pt={4}>
      <Accordion headerLabel='Data Table' headerIcon={TocIcon}>
        <DataTable<ReportItem>
          data={reports}
          meta={meta}
          isLoading={isLoading}
          headerCells={headerCells}
          getData={getReports}
        />
      </Accordion>
    </Box>
  );
};

export default YahooReportDataTable;
