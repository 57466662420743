import { useEffect, useState } from 'react';
import {
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import * as _ from 'lodash';
import TocIcon from '@material-ui/icons/Toc';
import Accordion from 'src/components/containers/Accordion/Accordion';
import ReportingService from '../../../../services/reports';

function SearchesTable() {
  const [data, setData] = useState<any[]>([]);
  const sort = 'asc';
  const order = 'desc';

  useEffect(() => {
    ReportingService.getSearchesReport().then((axiosData: any) => {
      const groupByDay = _.groupBy(axiosData.data.rows, 'date');

      const rows: any[] = Object.keys(groupByDay).map((date: string) => {
        return {
          date,
          numSearches: _.sumBy(groupByDay[date], 'COUNT(*)'),
        };
      });

      setData(_.orderBy(rows, 'date', 'desc'));
    });
  }, []);

  const headerCells = [
    { key: 'date', label: 'Date' },
    { key: 'numSearches', label: '# searches' },
  ];

  return (
    <Grid item xs={4}>
      <Accordion headerLabel='Searches in last 30 days' headerIcon={TocIcon}>
        <TableContainer component={Paper}>
          {data.length === 0 && <LinearProgress />}
          <Table>
            <TableHead>
              <TableRow>
                {headerCells.map((x: any) => (
                  <TableCell
                    className='Mui-reportCell'
                    key={x.key}
                    component='th'
                    scope='row'
                    sortDirection={order}
                  >
                    <TableSortLabel active={x.key === sort} direction={order}>
                      {x.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row: any, idx: any) => (
                <TableRow key={idx}>
                  {headerCells.map((x: any) => (
                    <TableCell
                      className='Mui-reportCell'
                      align='left'
                      key={x.key}
                    >
                      {row[x.key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
      </Accordion>
    </Grid>
  );
}

export default SearchesTable;
