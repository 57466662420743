import React from 'react';
import { Switch } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { UnauthorizedRoute, PrivateRoute } from './routes';
import useUserData from 'src/hooks/useUserData';
import SignIn from '../../views/SignIn';
import SignUp from '../../views/SignUp';
import Reset from '../../views/ResetPassword';
import AppContent from './AppContent';
import theme from 'src/styles/theme';

const App: React.FC = () => {
  const { isWaiting } = useUserData();

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <ToastContainer newestOnTop position='bottom-left' />
        <CssBaseline />
        {isWaiting ? (
          <LinearProgress />
        ) : (
          <Switch>
            <UnauthorizedRoute path='/sign-in' component={SignIn} />
            <UnauthorizedRoute path='/sign-up' component={SignUp} />
            <UnauthorizedRoute path='/reset-password' component={Reset} />

            <PrivateRoute path='/' component={AppContent} />
          </Switch>
        )}
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default App;
