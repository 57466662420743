import React from 'react';

const TopData: React.FC = () => {
  return null;
  // <Box maxWidth={1280} pt={4}>
  //   <Grid container spacing={4}>
  //     <Grid item xs={4}>
  //       <Accordion
  //         headerLabel='Top Partners'
  //         headerIcon={PersonIcon}
  //         defaultExpanded={false}
  //       >
  //         <Typography variant='body1'>Top Partners</Typography>
  //       </Accordion>
  //     </Grid>
  //     <Grid item xs={4}>
  //       <Accordion
  //         headerLabel='Top Sources'
  //         headerIcon={PersonIcon}
  //         defaultExpanded={false}
  //       >
  //         <Typography variant='body1'>Top Sources</Typography>
  //       </Accordion>
  //     </Grid>
  //     <Grid item xs={4}>
  //       <Accordion
  //         headerLabel='Top Markets'
  //         headerIcon={PersonIcon}
  //         defaultExpanded={false}
  //       >
  //         <Typography variant='body1'>Top Markets</Typography>
  //       </Accordion>
  //     </Grid>
  //   </Grid>
  // </Box>
};

export default TopData;
