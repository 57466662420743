import { Role } from 'src/types/Role';

export class User {
  public id?: string;
  public role: Role;
  public name: string;
  public email: string;
  public picture?: string;
  public password?: string;
  public repeatPassword?: string;
  public partnerId?: number;
  public partnerName?: string;
  public createdAt?: string;
  public updatedAt?: string;

  public constructor(props: any) {
    Object.assign(this, props);

    this.role = props.roleId;
  }
}
