import React, { useCallback, useEffect } from 'react';
import { object, string } from 'yup';
import Modal from '../containers/Modal/Modal';
import { useFormik, FormikHelpers, FormikErrors } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
} from '@material-ui/core';
import {
  createSocialMedia,
  updateSocialMedia,
} from 'src/store/socialMedias/actions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IMetaData } from 'src/types/MetaData';
import { number } from 'yup';
import { SocialMedia } from 'src/models';
import { AllState } from 'src/types/AllState';

interface Props {
  open: boolean;
  socialMedia: SocialMedia | null;
  handleClose: () => void;
  getSocialMedias: (meta?: Partial<IMetaData>) => void;
}

interface FormProps {
  name: string;
  url: string;
  rank: number;
  partnerId?: string | null;
}

const initialValues: FormProps = {
  name: '',
  url: '',
  rank: 0,
  partnerId: null,
};

const validationSchema = object().shape({
  name: string().required('Name is required.'),
  url: string().required('Url address is required.'),
  rank: number().required('Rank is required.'),
});

const SocialMediaForm: React.FC<Props> = ({
  open,
  socialMedia,
  handleClose,
  getSocialMedias,
}) => {
  const dispatch = useDispatch();

  const partners = useSelector(
    ({ promotions }: AllState) => promotions.partners
  );

  const onSubmit = useCallback(
    async (
      { name, url, rank, partnerId }: FormProps,
      form: FormikHelpers<FormProps>
    ) => {
      const func = socialMedia ? updateSocialMedia : createSocialMedia;

      const error: string | null | FormikErrors<FormProps> = (await dispatch(
        func({
          id: socialMedia?.id,
          name,
          url,
          rank,
          partnerId: partnerId ?? undefined,
        })
      )) as any;

      if (error) {
        typeof error === 'string' ? toast.error(error) : form.setErrors(error);
      } else {
        getSocialMedias();
        handleClose();
        toast.success(
          socialMedia
            ? 'Social Media updated successfully!'
            : 'Social Media created successfully!'
        );
      }
    },
    [dispatch, getSocialMedias, handleClose, socialMedia]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (socialMedia) {
      const { name, url, rank, partnerId } = socialMedia;
      formik.setValues({
        name,
        url,
        rank,
        partnerId,
      });
    }
    // eslint-disable-next-line
  }, [socialMedia]);

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      header={`${socialMedia ? 'Edit' : 'Add'} Social media`}
    >
      <Box pb={3} px={2}>
        <form onSubmit={formik.handleSubmit}>
          <Box pb={5}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id='name'
                  name='name'
                  label='Name'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  InputLabelProps={{ className: 'Mui-black' }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id='url'
                  name='url'
                  label='Url'
                  value={formik.values.url}
                  onChange={formik.handleChange}
                  error={formik.touched.url && Boolean(formik.errors.url)}
                  helperText={formik.touched.url && formik.errors.url}
                  InputLabelProps={{ className: 'Mui-black' }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.partnerId && Boolean(formik.errors.partnerId)
                  }
                >
                  <InputLabel>Partner</InputLabel>
                  <Select
                    value={formik.values.partnerId}
                    onChange={formik.handleChange}
                    name='partnerId'
                  >
                    {partners.map((x) => (
                      <MenuItem key={x.id} value={x.id}>
                        {x.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.partnerId && formik.errors.partnerId}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Rank</InputLabel>
                <Slider
                  defaultValue={0}
                  valueLabelDisplay='auto'
                  value={formik.values.rank}
                  onChange={(e, value) =>
                    formik.handleChange({ target: { name: 'rank', value } })
                  }
                  step={5}
                  marks
                  min={0}
                  max={100}
                />
              </Grid>
            </Grid>
          </Box>
          <Button variant='contained' type='submit' color='primary'>
            Save
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default SocialMediaForm;
