import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik, FormikHelpers, FormikErrors } from 'formik';
import { toast } from 'react-toastify';
import { object, string, ref } from 'yup';
import {
  Box,
  Button,
  Grid,
  TextField,  
} from '@material-ui/core';

interface FormProps {
  email: string;
  password: string;
  repeatPassword: string;
}

const initialValues: FormProps = {
  email: '',
  password: '',
  repeatPassword: '',
};

const validationSchema = object().shape({
  email: string()
    .required('Email address is required.')
    .email('Email address not formed correctly.'),
  password: string()
    .required('Password is required.')
    .min(8, 'Password must be 8 or more characters.')
    .max(20, 'Password must be not more than 20 characters.'),
  repeatPassword: string()
    .required('Confirm password is required.')
    .oneOf([ref('password'), null], 'Passwords must match.'),
});

interface Props {
  reset: any;
}


const ResetForm: React.FC<Props> = ({ reset }) => {

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const token = query.get('token');

  const onSubmit = useCallback(
    async (
      { email, password, repeatPassword }: FormProps,
      form: FormikHelpers<FormProps>
    ) => {

          
      
      

      const error: string | null | FormikErrors<FormProps> = await reset({
        token,
        email,
        password,
        repeatPassword,
      });
      // Cho nay can link den Chrome app
      // @ts-ignore
      if (error.data.status === 'success') {
        // @ts-ignore
        toast.success(error.data.message, {
          onClose: () => {
            window.location.href = '/sign-in'
          }
        });

      } else {
        // @ts-ignore
        toast.error(error.data.message);
      }
    },
    // eslint-disable-next-line
    [reset]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box pb={5}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id='email'
              name='email'
              label='Email'
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputLabelProps={{ className: 'Mui-black' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id='password'
              name='password'
              label='Password'
              type='password'
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputLabelProps={{ className: 'Mui-black' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id='repeatPassword'
              name='repeatPassword'
              label='Confirm password'
              type='password'
              value={formik.values.repeatPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.repeatPassword &&
                Boolean(formik.errors.repeatPassword)
              }
              helperText={
                formik.touched.repeatPassword && formik.errors.repeatPassword
              }
              InputLabelProps={{ className: 'Mui-black' }}
            />
          </Grid>
        </Grid>
      </Box>
      <Button
        fullWidth
        variant='contained'
        type='submit'
        color='primary'
        className='Mui-submitFormBtn'
      >
        Reset Password
      </Button>
    </form>
  );
};

export default ResetForm;
