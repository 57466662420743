import React, { useCallback, useEffect } from 'react';
import { object, string, ref } from 'yup';
import Modal from '../containers/Modal/Modal';
import { useFormik, FormikHelpers, FormikErrors } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { createUser, updateUser } from 'src/store/users/actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Role, roles } from 'src/types/Role';
import { IMetaData } from 'src/types/MetaData';
import { number } from 'yup';
import { User } from 'src/models';

interface Props {
  open: boolean;
  user: User | null;
  handleClose: () => void;
  getUsers: (meta?: Partial<IMetaData>) => void;
}

interface FormProps {
  name: string;
  email: string;
  password: string;
  repeatPassword: string;
  role?: Role;
}

const initialValues: FormProps = {
  name: '',
  email: '',
  password: '',
  repeatPassword: '',
  role: 1,
};

const validationSchema = object().shape({
  name: string().required('Name is required.'),
  email: string()
    .required('Email address is required.')
    .email('Email address not formed correctly.'),
  password: string()
    .required('Password is required.')
    .min(8, 'Password must be 8 or more characters.')
    .max(20, 'Password must be not more than 20 characters.'),
  repeatPassword: string()
    .required('Confirm password is required.')
    .oneOf([ref('password'), null], 'Passwords must match.'),
  role: number().required('Role is required.'),
});

const UserForm: React.FC<Props> = ({ open, user, handleClose, getUsers }) => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (
      { name, email, password, role }: FormProps,
      form: FormikHelpers<FormProps>
    ) => {
      const func = user ? updateUser : createUser;

      const error: string | null | FormikErrors<FormProps> = (await dispatch(
        func({
          id: user?.id,
          name,
          email,
          password,
          role,
        })
      )) as any;

      if (error) {
        typeof error === 'string' ? toast.error(error) : form.setErrors(error);
      } else {
        getUsers();
        handleClose();
        toast.success(
          user ? 'User updated successfully!' : 'User created successfully!'
        );
      }
    },
    [dispatch, getUsers, handleClose, user]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (user) {
      const { name, email, role } = user;

      formik.setValues({
        name,
        email,
        role,
        password: 'backdrop',
        repeatPassword: 'backdrop',
      });
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      header={`${user ? 'Edit' : 'Add'} User`}
    >
      <Box pb={3} px={2}>
        <form onSubmit={formik.handleSubmit}>
          <Box pb={5}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id='name'
                  name='name'
                  label='Name'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  InputLabelProps={{ className: 'Mui-black' }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id='email'
                  name='email'
                  label='Email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  InputLabelProps={{ className: 'Mui-black' }}
                />
              </Grid>
              {!user && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id='password'
                      name='password'
                      label='Password'
                      type='password'
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      InputLabelProps={{ className: 'Mui-black' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id='repeatPassword'
                      name='repeatPassword'
                      label='Confirm password'
                      type='password'
                      value={formik.values.repeatPassword}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.repeatPassword &&
                        Boolean(formik.errors.repeatPassword)
                      }
                      helperText={
                        formik.touched.repeatPassword &&
                        formik.errors.repeatPassword
                      }
                      InputLabelProps={{ className: 'Mui-black' }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={formik.touched.role && Boolean(formik.errors.role)}
                >
                  <InputLabel>Role</InputLabel>
                  <Select
                    value={formik.values.role}
                    onChange={formik.handleChange}
                    name='role'
                  >
                    {roles.map((x) => (
                      <MenuItem key={x} value={Role[x]}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.role && formik.errors.role}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Button variant='contained' type='submit' color='primary'>
            Save
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default UserForm;
