import { AxiosResponse } from 'axios';
import * as types from './actionTypes';
import PromotionsService from 'src/services/promotions';
import { Partner, Promotion } from 'src/models';
import { IMetaData } from 'src/types/MetaData';
import { AllState } from 'src/types/AllState';

export function setWaiting(val: boolean) {
  return {
    type: types.SET_WAITING,
    payload: val,
  };
}

export function getPartners(): (
  dispatch: any,
  getState: any
) => Promise<boolean> {
  return async (dispatch: any, getState: any): Promise<boolean> => {
    try {
      const { data } = await PromotionsService.getPartners();

      if (data.partners) {
        await dispatch({
          type: types.SET_PARTNERS,
          payload: data.partners.map((x: any) => new Partner(x)),
        });
      }

      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };
}

export function deletePromotion(
  id: string
): (dispatch: any, getState: any) => Promise<boolean> {
  return async (dispatch: any, getState: any): Promise<boolean> => {
    try {
      await PromotionsService.deletePromotion(id);

      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };
}

export function getPromotions(
  meta?: Partial<IMetaData>
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const { promotions }: AllState = getState();
      const metaData: IMetaData = { ...promotions.meta, ...(meta ?? {}) };

      await dispatch({
        type: types.SET_WAITING,
        payload: true,
      });

      await dispatch({
        type: types.APPEND_META,
        payload: metaData,
      });

      const axiosResponse: AxiosResponse = await PromotionsService.getPromotions(
        metaData
      );

      await dispatch({
        type: types.GET_PROMOTIONS_SUCCESS,
        payload: {
          data: axiosResponse.data.promotions.rows.map(
            (x: any) => new Promotion(x)
          ),
          meta: axiosResponse.data.promotions.meta,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch({
        type: types.SET_WAITING,
        payload: false,
      });
    }
  };
}

export function createPromotion(
  data: Partial<Promotion>
): (dispatch: any, getState: any) => Promise<string | null> {
  return async (dispatch: any, getState: any): Promise<string | null> => {
    try {
      await PromotionsService.createPromotion(data);

      return null;
    } catch (error) {
      console.log(error);

      const { response } = error;
      const data = response?.data;

      const errorMessage =
        data?.message ??
        'A server error has occured while attempting to create promotion.';

      return data?.errors ?? errorMessage;
    }
  };
}

export function updatePromotion(
  data: Partial<Promotion>
): (dispatch: any, getState: any) => Promise<string | null> {
  return async (dispatch: any, getState: any): Promise<string | null> => {
    try {
      await PromotionsService.updatePromotion(data);

      return null;
    } catch (error) {
      console.log(error);

      const { response } = error;
      const data = response?.data;

      const errorMessage =
        data?.message ??
        'A server error has occured while attempting to update promotion.';

      return data?.errors ?? errorMessage;
    }
  };
}
