export interface IMetaData {
  totalItems: number;
  totalPages: number;
  page: number;
  limit: number;
  sort: string;
  order: 'asc' | 'desc';
  queryParams?: Record<string, string | number> | null;
}

export const getInitialMeta = (meta: Partial<IMetaData>): IMetaData => {
  return {
    totalItems: 0,
    totalPages: 0,
    page: 0,
    limit: 5,
    sort: '',
    order: 'asc',
    ...meta,
  };
};
