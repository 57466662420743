import React, { useCallback, useEffect } from 'react';
import { object, string } from 'yup';
import Modal from '../containers/Modal/Modal';
import { useFormik, FormikHelpers, FormikErrors } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
} from '@material-ui/core';
import {
  createPromotion,
  getPartners,
  updatePromotion,
} from 'src/store/promotions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IMetaData } from 'src/types/MetaData';
import { number } from 'yup';
import { Promotion, User } from 'src/models';
import { AllState } from 'src/types/AllState';

const categories = [
  'Entertainment and Games',
  'Shopping and Coupons',
  'Home and Office',
  'Money and Savings',
  'Utilities and Tools',
];

interface Props {
  open: boolean;
  user: User | null;
  handleClose: () => void;
  promotion: Promotion | null;
  type: 'bestofchrome' | 'favorites';
  getPromotions: (meta?: Partial<IMetaData>) => void;
}

interface FormProps {
  name: string;
  url: string;
  rank: number;
  category: string;
  promotionType: 'bestofchrome' | 'favorites';
  icon?: string | null;
  partnerId?: string | null;
  userId?: string | null;
}

const initialValues: FormProps = {
  name: '',
  url: '',
  rank: 0,
  category: '',
  promotionType: 'bestofchrome',
  icon: null,
  partnerId: null,
  userId: null,
};

const validationSchema = object().shape({
  name: string().required('Name is required.'),
  url: string().required('Url address is required.'),
  rank: number().required('Rank is required.'),
  category: string().required('Category is required.'),
  promotionType: string().required('Category is required.'),
});

const PromotionForm: React.FC<Props> = ({
  open,
  type,
  user,
  promotion,
  handleClose,
  getPromotions,
}) => {
  const dispatch = useDispatch();

  const partners = useSelector(
    ({ promotions }: AllState) => promotions.partners
  );

  const onSubmit = useCallback(
    async (
      { name, url, rank, category, icon, partnerId, userId }: FormProps,
      form: FormikHelpers<FormProps>
    ) => {
      const func = promotion ? updatePromotion : createPromotion;

      const error: string | null | FormikErrors<FormProps> = (await dispatch(
        func({
          id: promotion?.id,
          name,
          url,
          rank,
          icon: icon ?? undefined,
          category,
          promotionType: type,
          partnerId: partnerId ?? undefined,
          userId: userId ?? (type === 'favorites' ? user?.id : undefined),
        })
      )) as any;

      if (error) {
        typeof error === 'string' ? toast.error(error) : form.setErrors(error);
      } else {
        getPromotions({ queryParams: { promotionType: type } });
        handleClose();
        toast.success(
          promotion
            ? 'Promotion updated successfully!'
            : 'Promotion created successfully!'
        );
      }
    },
    [dispatch, getPromotions, handleClose, promotion, type, user]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    dispatch(getPartners());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (promotion) {
      const {
        name,
        url,
        rank,
        category,
        promotionType,
        icon,
        userId,
        partnerId,
      } = promotion;
      formik.setValues({
        name,
        url,
        rank,
        icon,
        category,
        promotionType,
        userId,
        partnerId,
      });
    }
    // eslint-disable-next-line
  }, [promotion]);

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      header={`${promotion ? 'Edit' : 'Add'} Promotion`}
    >
      <Box pb={3} px={2}>
        <form onSubmit={formik.handleSubmit}>
          <Box pb={5}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id='name'
                  name='name'
                  label='Name'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  InputLabelProps={{ className: 'Mui-black' }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id='url'
                  name='url'
                  label='Url'
                  value={formik.values.url}
                  onChange={formik.handleChange}
                  error={formik.touched.url && Boolean(formik.errors.url)}
                  helperText={formik.touched.url && formik.errors.url}
                  InputLabelProps={{ className: 'Mui-black' }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.partnerId && Boolean(formik.errors.partnerId)
                  }
                >
                  <InputLabel>Partner</InputLabel>
                  <Select
                    value={formik.values.partnerId}
                    onChange={formik.handleChange}
                    name='partnerId'
                  >
                    {partners.map((x) => (
                      <MenuItem key={x.id} value={x.id}>
                        {x.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.partnerId && formik.errors.partnerId}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.category && Boolean(formik.errors.category)
                  }
                >
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={formik.values.category}
                    onChange={formik.handleChange}
                    name='category'
                  >
                    {categories.map((x) => (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.category && formik.errors.category}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Rank</InputLabel>
                <Slider
                  defaultValue={0}
                  valueLabelDisplay='auto'
                  value={formik.values.rank}
                  onChange={(e, value) =>
                    formik.handleChange({ target: { name: 'rank', value } })
                  }
                  step={5}
                  marks
                  min={0}
                  max={100}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id='icon'
                  name='icon'
                  label='Icon'
                  value={formik.values.icon}
                  onChange={formik.handleChange}
                  error={formik.touched.icon && Boolean(formik.errors.icon)}
                  helperText={formik.touched.icon && formik.errors.icon}
                  InputLabelProps={{ className: 'Mui-black' }}
                />
              </Grid>
            </Grid>
          </Box>
          <Button variant='contained' type='submit' color='primary'>
            Save
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default PromotionForm;
