import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useFormik, FormikHelpers, FormikErrors } from 'formik';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import {
  Box,
  Button,
  Grid,
  Link as MuiLink,
  TextField,
} from '@material-ui/core';

interface FormProps {
  email: string;
  password: string;
}

const initialValues: FormProps = {
  email: '',
  password: '',
};

const validationSchema = object().shape({
  email: string()
    .required('Email address is required.')
    .email('Email address not formed correctly.'),
  password: string().required('Password is required.'),
});

interface Props {
  login: (data: any) => Promise<string | null | FormikErrors<FormProps>>;
}

const SignIn: React.FC<Props> = ({ login }) => {
  const onSubmit = useCallback(
    async ({ email, password }: FormProps, form: FormikHelpers<FormProps>) => {
      const error: string | null | FormikErrors<FormProps> = await login({
        email,
        password,
      });

      if (error) {
        typeof error === 'string' ? toast.error(error) : form.setErrors(error);
      }
    },
    [login]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box pb={5}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id='email'
              name='email'
              label='Email'
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputLabelProps={{ className: 'Mui-black' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id='password'
              name='password'
              label='Password'
              type='password'
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputLabelProps={{ className: 'Mui-black' }}
            />
          </Grid>
        </Grid>
      </Box>
      <Button
        fullWidth
        variant='contained'
        type='submit'
        color='primary'
        className='Mui-submitFormBtn'
      >
        Log in
      </Button>
      <Box pt={3} textAlign='center'>
        <MuiLink component={Link} to='/sign-up'>
          Sign up
        </MuiLink>
      </Box>
    </form>
  );
};

export default SignIn;
