import { AxiosResponse } from 'axios';
import * as types from './actionTypes';
import SocialMediasService from 'src/services/socialMedias';
import { SocialMedia } from 'src/models';
import { IMetaData } from 'src/types/MetaData';
import { AllState } from 'src/types/AllState';

export function setWaiting(val: boolean) {
  return {
    type: types.SET_WAITING,
    payload: val,
  };
}

export function deleteSocialMedia(
  id: string
): (dispatch: any, getState: any) => Promise<boolean> {
  return async (dispatch: any, getState: any): Promise<boolean> => {
    try {
      await SocialMediasService.deleteSocialMedia(id);

      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };
}

export function getSocialMedias(
  meta?: Partial<IMetaData>
): (dispatch: any, getState: any) => Promise<void> {
  return async (dispatch: any, getState: any): Promise<void> => {
    try {
      const { socialMedias }: AllState = getState();
      const metaData: IMetaData = { ...socialMedias.meta, ...(meta ?? {}) };

      await dispatch({
        type: types.SET_WAITING,
        payload: true,
      });

      await dispatch({
        type: types.APPEND_META,
        payload: metaData,
      });

      const axiosResponse: AxiosResponse = await SocialMediasService.getSocialMedias(
        metaData
      );

      await dispatch({
        type: types.GET_SOCIAL_MEDIAS_SUCCESS,
        payload: {
          data: axiosResponse.data.socialMedias.rows.map(
            (x: any) => new SocialMedia(x)
          ),
          meta: axiosResponse.data.socialMedias.meta,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      await dispatch({
        type: types.SET_WAITING,
        payload: false,
      });
    }
  };
}

export function createSocialMedia(
  data: Partial<SocialMedia>
): (dispatch: any, getState: any) => Promise<string | null> {
  return async (dispatch: any, getState: any): Promise<string | null> => {
    try {
      await SocialMediasService.createSocialMedia(data);

      return null;
    } catch (error) {
      console.log(error);

      const { response } = error;
      const data = response?.data;

      const errorMessage =
        data?.message ??
        'A server error has occured while attempting to create social media.';

      return data?.errors ?? errorMessage;
    }
  };
}

export function updateSocialMedia(
  data: Partial<SocialMedia>
): (dispatch: any, getState: any) => Promise<string | null> {
  return async (dispatch: any, getState: any): Promise<string | null> => {
    try {
      await SocialMediasService.updateSocialMedia(data);

      return null;
    } catch (error) {
      console.log(error);

      const { response } = error;
      const data = response?.data;

      const errorMessage =
        data?.message ??
        'A server error has occured while attempting to update social media.';

      return data?.errors ?? errorMessage;
    }
  };
}
