import { AxiosResponse } from 'axios';
import { IMetaData } from 'src/types/MetaData';
import { metaToQueryParams } from 'src/utils/meta';
import api from './api';

class ReportsService {
  public async getReports(meta?: IMetaData): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/reporting' + (meta ? metaToQueryParams(meta) : ''),
    });
  }

  public async getSearchesReport(meta?: IMetaData): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/reporting/searches' + (meta ? metaToQueryParams(meta) : ''),
    });
  }

  public async getAdoptionReport(meta?: IMetaData): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/reporting/adoption' + (meta ? metaToQueryParams(meta) : ''),
    });
  }

  public async getReportFilterOptions(): Promise<AxiosResponse> {
    return await api({
      method: 'get',
      url: '/api/reporting-filters',
    });
  }
}

export default new ReportsService();
