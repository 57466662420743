export class ReportItem {
  public date: Date;
  public search_type: string;
  public source: string;
  public market: string;
  public user_country: string;
  public device: string;
  public product: string;
  public searches: number;
  public bidded_searches: number;
  public bidded_clicks: number;
  public bidded_results: number;
  public tq_score: number;
  public revenue_usd: number;

  public constructor(props: any) {
    Object.assign(this, props);

    this.revenue_usd = parseFloat(props.revenue_usd);
  }
}
