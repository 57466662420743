import React, { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { Link } from '@material-ui/core';
import { Promotion } from 'src/models';
import { IMetaData } from 'src/types/MetaData';
import DataTable from 'src/components/table/DataTable';

interface Props {
  promotions: Promotion[];
  meta: IMetaData;
  isLoading?: boolean;
  getData: (meta?: Partial<IMetaData>) => Promise<void>;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
}

const PromotionsTable: React.FC<Props> = ({
  promotions,
  meta,
  isLoading,
  getData,
  onDelete,
  onEdit,
}) => {
  const renderRow = useCallback((prop: keyof Promotion, row: Promotion) => {
    switch (prop) {
      case 'name':
        return (
          <Link
            component='a'
            target='_blank'
            href={row.url.includes('http') ? row.url : `http://${row.url}`}
          >
            {row[prop]}
          </Link>
        );
      case 'promotionType':
        return row[prop] === 'bestofchrome' ? 'chrome extensions' : row[prop];
      case 'updatedAt':
      case 'createdAt':
        return DateTime.fromISO(row[prop]?.toString() ?? '').toLocaleString(
          DateTime.DATETIME_SHORT
        );
      default:
        return row[prop];
    }
  }, []);

  const cells: { label: string; key: keyof Promotion }[] = useMemo(
    () => [
      { label: 'Name', key: 'name' },
      { label: 'Category', key: 'category' },
      { label: 'Rank', key: 'rank' },
      { label: 'Partner', key: 'partnerName' },
      { label: 'Icon', key: 'icon' },
      { label: 'Updated at', key: 'updatedAt' },
      { label: 'Created at', key: 'createdAt' },
    ],
    []
  );

  return (
    <DataTable<Promotion>
      useActions
      data={promotions}
      meta={meta}
      headerCells={cells}
      isLoading={!!isLoading}
      renderCell={renderRow}
      dispatchForGetData={false}
      getData={getData}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
};

export default PromotionsTable;
