import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { AllState } from 'src/types/AllState';
import { Role } from 'src/types/Role';
import NotFound from '../Error';
import UsersManagement from './components/Users';
import PromotionsManagement from 'src/components/common/Promotions';
import SocialMediasManagement from './components/SocialMedias';

const Admin: React.FC = () => {
  const loggedInUser = useSelector(({ auth }: AllState) => auth.loggedInUser);

  const RootWrapper = useCallback(
    ({ children }) => {
      return loggedInUser?.role === Role.admin ? children : <NotFound />;
    },
    [loggedInUser]
  );

  return (
    <RootWrapper>
      <Typography variant='h5'>Admin</Typography>
      <UsersManagement />
      <PromotionsManagement type='bestofchrome' />
      <SocialMediasManagement />
    </RootWrapper>
  );
};

export default Admin;
